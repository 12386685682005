.info-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    min-height: calc(100vh - 120px);
}

.info-content {
    display: flex;
    gap: 40px;
    background: rgba(255, 255, 255, 0.8);
    padding: 30px;
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.1);
    backdrop-filter: blur(4px);
}

.info-left {
    flex: 0 0 300px;
    position: sticky;
    top: 100px;
    height: fit-content;
}

.profile-section {
    text-align: center;
    margin-bottom: 30px;
}

.profile-image-container {
    width: 200px;
    height: 200px;
    margin: 0 auto 20px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    position: relative;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.name {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
    font-family: 'Optima', serif;
}

.title {
    color: #666;
    font-size: 16px;
    margin-bottom: 15px;
}

.social-links {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
}

.social-link {
    color: #333;
    font-size: 24px;
    transition: color 0.3s;
}

.social-link:hover {
    color: #00a5f8;
}

.email-link {
    display: block;
    text-align: center;
    margin-top: 15px;
    color: #00a5f8;
    text-decoration: underline;
    font-size: 14px;
    transition: color 0.3s;
}

.email-link:hover {
    color: #0077cc;
}

.info-nav {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.nav_switch_button {
    padding: 12px 20px;
    border: none;
    background: transparent;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: left;
    color: #666;
    border-radius: 30 px;
}

.nav_switch_button:hover {
    background: rgba(0, 165, 248, 0.1);
    color: #00a5f8;
}

.nav_switch_button.active {
    background: #00a5f8;
    color: white;
}

.info-right {
    flex: 1;
    min-height: 600px;
}

.about-section {
    animation: fadeIn 0.5s ease-out;
}

.bio {
    font-size: 16px;
    line-height: 1.6;
    color: #444;
    margin-bottom: 30px;
}

.bio p {
    margin-bottom: 20px;
}

.bio p:last-child {
    margin-bottom: 0;
}

.cv-section {
    animation: fadeIn 0.5s ease-out;
}

.cv-document {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.cv-page {
    margin-bottom: 20px;
}

.photo-gallery {
    margin-top: 40px;
}

.photo-gallery h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
}

.photo-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.photo-item {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    aspect-ratio: 4/3;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.photo-item:hover {
    transform: translateY(-5px);
}

.photo-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.photo-item:hover img {
    transform: scale(1.05);
}

.video-gallery {
    margin-top: 40px;
}

.video-gallery h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
}

.video-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;
    position: relative;
}

.video-item {
    position: relative;
    overflow: hidden;
    aspect-ratio: 1/1;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    z-index: 1;
}

.video-item:hover {
    transform: translateY(-5px) scale(1.5);
    z-index: 100;
    position: relative;
}

.video-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* 响应式调整 */
@media (max-width: 768px) {
    .info-content {
        flex-direction: column;
    }

    .info-left {
        position: static;
        width: 100%;
    }

    .profile-image-container {
        width: 150px;
        height: 150px;
    }

    .photo-grid {
        grid-template-columns: 1fr;
    }

    .video-grid {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 1200px) {
    .video-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}