/* for embedded video */
.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 12.5%;
    top: 12.5%;
    height: 75%;
    width: 75%;
    position: absolute;
}

.video-description{
    position: relative;
    text-align: center;
    margin-top: -2rem;
    z-index: 1000;
    font-family: 'Optima', sans-serif;
}

.video_content {
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    overflow: hidden;
    width: 853px;
    height: 480px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.video_content.loaded {
    opacity: 1;
}

.after_video {
    text-align: center;
    margin-top: 2rem;
    z-index: 1000;
    font-family: 'Optima', sans-serif;
}

.project-detail {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    overflow-y: auto;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.project-detail.loading {
    overflow: hidden;
    opacity: 0;
}

.iframe-container {
    width: 100%;
    height: 110vh;
    overflow: hidden;
    border: none;
    position: relative;
}

.iframe-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    transition: opacity 0.3s ease;
}

.start-button {
    padding: 1rem 2rem;
    font-size: 1.2rem;
    background: transparent;
    border: 2px solid white;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: 'Segoe UI', sans-serif;
}

.start-button:hover {
    background: white;
    color: black;
    transform: scale(1.1);
}

.control-button-back {
    position: fixed;
    top: 120px;
    left: 2rem;
    background: none;
    border: 2px solid #474283;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #474283;
    font-size: 1.5em;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 1001;
}

.control-button-back:hover {
    background-color: #474283;
    color: white;
    transform: scale(1.1);
}

/* super link */
.highlight-link{
    color: #474283;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;
    font-family: 'Segoe UI', sans-serif;
    font-size: 30px;
}

/* 居中讲解字 */
.project-description {
    text-align: left;
    max-width: 75%;
    margin: 4rem auto;
    padding: 0 2rem;
    font-size: 1rem;
    line-height: 1.8;
    color: rgb(0, 0, 0);
    font-family: 'Segoe UI', sans-serif;
}

.project-description p {
    margin-bottom: 2rem;
}

/* for image viwer */

.project-image {
    max-width: 70%;
    height: auto;
    margin: 2rem auto;
    display: block;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.project-image:hover {
    transform: scale(1.02);
}

.image-viewer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;  
}

.fullscreen-image {
    max-width: 90%;
    max-height: 90vh;
    object-fit: contain;
}

.nav-button {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);  
    border: none;
    color: white;  
    font-size: 2rem;
    cursor: pointer;
    z-index: 9999;  
    padding: 1rem;  
    border-radius: 50%;  
    width: 4rem;  
    height: 4rem;  
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.nav-button:hover {
    background: rgba(0, 0, 0, 0.7);
    transform: translateY(-50%) scale(1.1);
}

.nav-button svg {
    width: 2rem;  
    height: 2rem;
    fill: rgb(136, 136, 255);  
}

.prev {
    left: 0.5rem;
}

.next {
    right: 0.5rem;
}

.close-button {
    position: fixed;
    top: 2rem;
    right: 2rem;
    background: rgba(255, 255, 255, 0.2);  
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    z-index: 10000;  
    padding: 0.5rem;  
    border-radius: 50%;  
    width: 3rem;  
    height: 3rem;  
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.close-button:hover {
    background: rgba(255, 255, 255, 0.4);  
}

.loading-indicator {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Optima', sans-serif;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.loading-indicator::after {
    content: '';
    width: 2rem;
    height: 2rem;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #474283;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

body {
    overflow-y: auto;  
    height: 100%;
}