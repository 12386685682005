*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 18px;
	--color-text: #5e5754;
	--color-title: #944d3e;
	--color-bg: rgb(247, 245, 240);
	/* --color-bg: rgb(12, 12, 12); */
	--color-link: #5e5754;
	--color-link-hover: #5e5754;
	--color-switch-bg: #f1e4dd;
	--color-switch: #000;
	--color-switch-active: #c1b7b1;
	--color-heading: #cbcbff;
}

html {
	scroll-behavior: initial;
}

html, body, #root {
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: var(--color-bg);
}

body {
	margin: 0;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: articulat-cf, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.App {
	height: 100%;
}

svg {
	/*   display: none; */
	  height: 0;
	  width: 0;
	  position: absolute;
	  animation: test 1s;
}

body #cdawrap {
	justify-self: start;
}

.oh {
    position: relative;
    overflow: hidden;
}

.oh__inner {
    will-change: transform;
    display: inline-block;
}

.line {
	transform-origin: 0;
	white-space: nowrap;
	will-change: transform;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
	cursor: pointer;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
	cursor: pointer;
	color: currentColor;
}

.unbutton:focus {
	outline: none;
}

.frame {
	position: relative;
	color: var(--color-title);
	padding: 1rem;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto auto auto;
	grid-template-areas: 'title' 'prev' 'sponsor';
	justify-content: start;
	justify-items: start;
	margin-bottom: 3rem;
	grid-gap: 0.5rem;
}

.frame a:not(.frame__title-back) {
	white-space: nowrap;
	overflow: hidden;
	position: relative;
}

.frame a:not(.frame__title-back)::before {
	content: '';
	height: 1px;
	width: 100%;
	background: currentColor;
	position: absolute;
	top: 90%;
	transition: transform 0.3s;
	transform-origin: 0% 50%;
}

.frame a:not(.frame__title-back):hover::before {
	transform: scaleX(0);
	transform-origin: 100% 50%;
}

.frame__title {
	grid-area: title;
	display: flex;
}

.frame__title-main {
	font-size: 1rem;
	margin: 0;
	font-weight: normal;
}

.frame__title-back {
	position: relative;
	display: flex;
	align-items: flex-end;
	margin-bottom: 0.15rem;
}

.frame__title-back span {
	display: none;
}

.frame__title-back svg {
	fill: currentColor;
}

.frame__prev {
	grid-area: prev;
}

.heading {
	position: fixed;
	top: 8rem;
	left: 0;
	width: 100%;
	z-index: 200;
	pointer-events: none;
	/* mix-blend-mode: color-dodge; */
	mix-blend-mode: difference;
}

.heading__main {
	display: inline-block;
	color: var(--color-heading);
	font-weight: 300;
	font-size: clamp(2rem,10vw,5.5rem);
	white-space: nowrap;
	margin: 0;
	margin-left: calc(13.25vw + 25vw + 1vw);
	line-height: 0.8;
	text-transform: uppercase;
	margin-top: 3.2rem;
	will-change: transform;
}

.heading__sub {
	display: inline-block;
	margin-left: calc(13.25vw + 25vw + 1vw + 1rem);
	color: var(--color-heading);
	font-size: 0.85rem;
}

.switch {
	padding: 1rem 1.5rem;
	position: fixed;
	background: var(--color-switch-bg);
	bottom: 1.5rem;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	z-index: 100;
	border-radius: 3rem;
}

.switch::after {
	content: '';
	width: 1px;
	height: calc(100% - 2rem);
	position: absolute;
	left: 50%;
	background: var(--color-switch-active);
}

.switch__button {
	display: flex;
	margin: 0 0.5rem;
	padding: 0 0.25rem;
	fill: var(--color-switch);
}

.switch__button--current {
	fill: var(--color-switch-active);
	pointer-events: none;
}

.switch__button svg {
	pointer-events: none;
}

.content {
	position: relative;
	padding: 20vh 13.25vw 2.5rem;
}

.grid-open {
	overflow: hidden;
	height: 100vh;
}

.grid-open .content {
	pointer-events: none;
}

.item {
	grid-gap: 5vh;
	grid-template-columns: 100%;
	align-content: center;
	justify-content: center;
	display: grid;
	grid-template-areas: 'caption' 'img';
	margin: 0 auto 20vh;
}

.item__image-wrap {
	grid-area: img;
	width: 100%;
	aspect-ratio: 800/500;
	justify-self: center;
}

.item__image {
	width: 100%;
	aspect-ratio: 800/500;
	position: relative;
	overflow: hidden;
	will-change: transform;
}

.item__image-inner {
	width: 100%;
	height: 100%;
	background-size: cover;
	will-change: transform;
}

.item__caption {
	grid-area: caption;
	position: relative;
	z-index: 10;
}

.item__caption-title {
	white-space: nowrap;
	width: min-content;
	line-height: 1.2;
	font-size: clamp(2rem,6vw,3.5rem);
	font-weight: 300;
	margin: 0;
	color: var(--color-title);
}

.item__caption-description {
	max-width: 200px;
	line-height: 1.2;
}

.item__caption-number {
	display: inline-block;
}

@media screen and (min-width: 53em) {
	.frame {
		grid-column-gap: 2rem;
		grid-template-columns: auto auto;
		grid-template-rows: auto auto;
		grid-template-areas: 'title prev' 'sponsor ...';
	}
	.item {
		grid-gap: 1vw;
	    grid-template-columns: 25vw 1fr;
	    grid-template-areas: 'caption img' ;
	}
	.content {
		padding-top: 40vh;
	}
	.content .item__image {
		grid-column: 2 / span 2;
	}
	.heading {
		top: 0;
	}
	.grid {
		left: calc((100vw - 950px ) / 2);
		max-width: 950px;
	}
}

.container {
display: flex;
height: 100vh;
}

.video-left {
filter: url(#filter1);
width: 50%;
object-fit: cover;
image-rendering: crisp-edges;
image-rendering: pixelated;
}

.video-right {
filter: url(#filter2);
width: 50%;
object-fit: cover;
image-rendering: crisp-edges;
image-rendering: pixelated;
}



.color {
background: linear-gradient(90deg, black 10%, white);
filter: url(#filter);
color: white;
image-rendering: crisp-edges;
image-rendering: pixelated;
a {
	color: white;
}
}
