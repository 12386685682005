/* Base styles */
:root {
    --color-text: #474283;
    --color-primary: #45cb96;
    --color-secondary: #349770;
    --transition-duration: 0.3s;
    --album-shadow: 2px 16px 26px 0px rgba(36, 33, 69, 0.3);
    --album-hover-shadow: 2px 20px 30px 0px rgba(36, 33, 69, 0.4);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    background-color: var(--color-background);
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    font-family: 'Lucida Sans Typewriter', 'Lucida Console', monaco, 'Bitstream Vera Sans Mono', monospace;
    background-color: var(--color-background);
    color: var(--color-text);
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    background-color: var(--color-background);
    min-height: 100vh;
}

.GridPage {
    width: 100%;
    min-height: 100vh;
    background-color: var(--color-background);
    padding: 0rem;
    margin-bottom: 200px; /* 为页脚留出空间 */
}

.content-wrapper {
    width: 100%;
    min-height: 80 vh;
    position: relative;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Grid layout */
.view {
    position: relative;
}

.view--grid {
    opacity: 1;
    pointer-events: auto;
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: var(--color-background);
}

.grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12rem;
    padding: 4rem;
    margin: 0 auto;
    max-width: 1800px;
    list-style: none;
    transform: translateX(-5%);
}

/* 设置行间距 */
.grid__item {
    margin-bottom: -8rem;
    position: relative;
    width: 100%;
}

.grid__item:nth-child(3n+1) {
    margin-top: 40%;
}

.grid__item:nth-child(3n+2) {
    margin-top: 80%;
}

.grid__item:nth-child(3n) {
    margin-top: 0;
}

.album--grid {
    width: 300px;
    height: 300px;
    position: relative;
}

.album--grid .album__cover {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: 2;
}

.album--grid .album__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: var(--album-shadow);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.album--grid:hover .album__vinyl {
    transform: translate3d(40%, 0, 0);
}

.album--grid .album__title {
    font-size: 1.6rem;
    margin: 1.5rem 0 0.8rem;
}

.album--grid .album__artist {
    font-size: 0.6rem;
}

.album--grid .album__honor {
    margin-top: 5%;
    width: 50%;
    height: 50%;
}

/* Album base styles */
.album {
    position: relative;
    width: 250px;
    height: 250px;
    margin: 0 auto;
}

.album__cover {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: 2;
}

.album__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: var(--album-shadow);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 2;
}

.album:hover .album__image {
    box-shadow: var(--album-hover-shadow);
    transform: scale(1.2);
    transition: transform 0.8s ease, box-shadow 0.8s ease;
}

.album__vinyl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #181819;
    border-radius: 50%;
    transform: translate3d(5%, 0, 0);
    transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1;
}

.album:hover .album__vinyl {
    transform: translate3d(-20%, 0, 0);
}

.album__vinyl::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15%;
    height: 15%;
    background: #474283;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.album__vinyl::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5%;
    height: 5%;
    background: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.album:hover .album__vinyl {
    transform: translate3d(30%, 0, 0);
}

.album__info {
    position: relative;
    padding: 1rem 0;
    z-index: 2;
}

.album__title {
    font-size: 1.4rem;
    margin: 1rem 0 0.5rem;
    font-weight: 600;
    color: #474283;
}

.album__artist {
    font-size: 0.5rem;
    margin: 0;
    opacity: 0.8;
}

.album__timestamp {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    opacity: 0.6;
}

/* Single View */
.view--single {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background);
    opacity: 0;
    transition: opacity var(--transition-duration);
    z-index: 1;
}

.view--single.is-visible {
    opacity: 1;
}

.view--single .album {
    position: relative;
    width: 600px;
    height: 600px;
    margin: -100px auto 0;
}

.view--single .album__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: var(--album-shadow);
}

.view--single .album:hover .album__image {
    box-shadow: 2px 20px 30px 0px rgba(36, 33, 69, 0.4);
    transform: translateY(-5px);
}

.view--single .album__info {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 10;
}

.view--single .album__counter {
    font-size: 3em;
    color: #474283;
    font-weight: 800;
    line-height: 1;
}

.view--single .album__counter-total {
    font-size: 0.4em;
    color: #474283;
    opacity: 0.7;
    margin-left: 4px;
}

.view--single .album__year {
    position: absolute;
    left: 0%;
    top: 105%;
    right: -120px;
    transform: translateY(-50%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    font-size: 0.9rem;
}

.view--single .album__honor {
    position: absolute;
    left: 25%;
    top: 90%;
    transform: scale(0.2);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.view--single .album__year-label {
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 2px;
    opacity: 0.7;
    color: #474283;
}

.view--single .album__year-value {
    font-size: 2.2em;
    font-weight: bold;
    line-height: 1;
    color: #474283;
}

.view--single .album__title {
    position: absolute;
    bottom: -80px;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 1.2rem;
    margin-top: 1rem;
    text-align: center;
}

.view--single .album__title-label {
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: 2px;
    opacity: 0.7;
    color: #474283;
}

.view--single .album__title-value {
    font-size: 2.5em;
    font-weight: bold;
    color: #474283;
    line-height: 1.2;
}

.view--single .control-button--play {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    width: 80px;
    height: 80px;
    border: none;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.view--single .control-button--play:hover {
    transform: translate(-50%, -50%) scale(1);
    background: #474283;
}

.view--single .control-button--play .icon {
    width: 32px;
    height: 32px;
    fill: #474283;
    transition: fill 0.3s ease;
}

.view--single .control-button--play:hover .icon {
    fill: white;
}

.view--single .nav-arrows {
    position: fixed;
    right: 40px;
    bottom: 40px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    z-index: 1001;
}

.view--single .nav-arrow {
    background: none;
    border: 2px solid #474283;
    border-radius: 50%;
    color: #474283;
    font-size: 1.5em;
    cursor: pointer;
    padding: 15px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.view--single .nav-arrow:hover {
    background-color: #474283;
    color: white;
    transform: scale(1.1);
}

.view--single .control-button--back {
    position: fixed;
    top: 120px;
    left: 2rem;
    background: none;
    border: 2px solid #474283;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #474283;
    font-size: 1.5em;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 1001;
}

.view--single .control-button--back:hover {
    background-color: #474283;
    color: white;
    transform: scale(1.1);
}

.view--single .album__vinyl {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    background: radial-gradient(circle at center,
            #000 0%,
            #000 30%,
            #181819 31%,
            #181819 58%,
            #000 59%,
            #000 61%,
            #181819 62%,
            #181819 100%);
    border-radius: 50%;
    transform: translate3d(-80%, 0, -10px) rotate(0deg);
    transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change: transform;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.view--single .album:hover .album__vinyl {
    transform: translate3d(-20%, 0, -10px) rotate(15deg);
}

/* Control Buttons */
.view--single .control-button--play {
    bottom: -80px;
    left: 50%;
    transform: translate3d(-50%, 20px, 0) scale(0.8);
    opacity: 0;
    transition-delay: 0.1s;
}

.view--single.is-visible .control-button--play {
    opacity: 1;
    transform: translate3d(-50%, 0, 0) scale(1);
}

.view--single .control-button--play:hover {
    transform: translate3d(-50%, 0, 0) scale(1.1);
    color: #45cb96;
}

/* Album Info in Single View */
.view--single .album__info {
    position: absolute;
    bottom: -120px;
    left: 0;
    width: 100%;
    text-align: center;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-delay: 0.2s;
}

.view--single.is-visible .album__info {
    opacity: 1;
    transform: translateY(0);
}

.view--single .album__title {
    font-size: 1.5em;
    margin: 0;
    color: #474283;
}

.view--single .album__artist {
    font-size: 1em;
    margin-top: 0em;
    color: rgba(71, 66, 131, 0.7);
}

/* Header styles */
.page-header {
    position: relative;
    margin: 0 auto;
    padding: 10vmin 4em 0;
    max-width: 1200px;
}

.page-header__title {
    font-family: 'nexa_boldregular', sans-serif;
    font-size: 7vmin;
    line-height: 1.25;
    margin: 0;
    padding: 0 0 0 1.65em;
}

.page-header__subtitle {
    display: block;
    max-width: calc(50vw - 5em);
    margin: 1em 0 0 5px;
    color: var(--color-text);
}

/* Responsive design */
@media (max-width: 1200px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
}

@media (max-width: 768px) {
    .grid {
        grid-template-columns: minmax(200px, 1fr);
        padding: 1rem;
    }

    .page-header__title {
        font-size: 2rem;
    }
}

.single-view-content {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
}

.albums-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150%;
    gap: -0px;
    position: relative;
    perspective: 2000px;
}

.album--preview {
    width: 300px;
    height: 300px;
    opacity: 0.4;
    transform-style: preserve-3d;
    transition: all 0.5s ease;
    cursor: pointer;
}

.album--preview.album--prev {
    transform: scale(0.7) rotateY(-25deg) translateX(-120px);
}

.album--preview.album--next {
    transform: scale(0.7) rotateY(25deg) translateX(120px);
}

.album--preview:hover {
    opacity: 0.8;
}

.album--preview.album--prev:hover {
    transform: scale(0.85) rotateY(-10deg) translateX(-10px);
}

.album--preview.album--next:hover {
    transform: scale(0.85) rotateY(10deg) translateX(10px);
}

.album--main {
    width: 600px;
    height: 600px;
    position: relative;
    z-index: 2;
    transform-style: preserve-3d;
    transform: translateZ(50px);
}

.view--single .album--preview .album__image {
    border-radius: 4px;
    box-shadow: var(--album-shadow);
    backface-visibility: hidden;
}


/* for cloud visual */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*
.cloud_container {
    position: absolute;
    left: 400px;
    top: 100px;
    height: 200px;
    width: 20%;
    display: flex;
    justify-content: center;
    animation: animateColor 10s linear infinite ;
    font-family: 'Poppins', sans-serif;
    --clr: #0f0;
}

@keyframes animateColor {
    0%{
        filter: hue-rotate(0deg);
    }
    100%{
        filter: hue-rotate(360deg);
    }
}

.cloud_container .cloud {
    position: relative;
    width: 500px;
    height: 300px;
    z-index: 100;
    filter: drop-shadow(0 0 35px var(--clr));
    transform: scale(0.5);
}

.cloud_container .cloud h2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    color: #000;
    font-size: 2em;
    z-index: 1000;
    font-weight: 400;
    padding:0 10px;
    border-radius: 10px;
    text-transform: uppercase;
    background: var(--clr);
}

.cloud_container .cloud h2::before {
    content: '';
    position: absolute;
    top: -115px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100px;
    width: 120%;
    height: 100px;
    background: var(--clr);
}

.cloud_container .cloud h2::after{
    content: '';
    position: absolute;
    top: -150px;
    left: 25px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: var(--clr);
    box-shadow: 120px -30px 0 10px var(--clr);
} 

.cloud_container .cloud .drop{
  position: absolute;
  top: 60px;
  height: 50px;
  line-height: 20px;
  color: var(--clr);
  transform-origin: bottom;
  animation: animate 2s linear infinite;
}

@keyframes animate {
    0%{
        transform: translateY(0) scaleY(0);
        transform-origin: top;
    }
    10%{
        transform: translateY(0) scaleY(0.25);
        transform-origin: top;
    }
    20%{
        transform: translateY(0) scaleY(1);
        /* transform-origin: top; */
    }
    
    70%{
        transform: translateY(1500px) scaleY(1);
        transform-origin: bottom;
    }
    80%{
        transform: translateY(1500px) scaleY(1);
        transform-origin: bottom;
    }
    100%{
        transform: translateY(1500px) scaleY(0);
        transform-origin: bottom;
        text-shadow: -180px 0 0 var(--clr), 180px 0 var(--clr);
    }
}