.navigation_container {
    /* 添加一个父容器 */
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 3%;  /* 减小高度 */
    z-index: 1000;
    background-color: var(--color-bg);
}

ul {
    margin: 15px 20px 0 0;  /* 减小上边距 */
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    /* 添加这一行 */
    width: auto;
    text-align: center;
}

li {
    display: table-cell;
    position: relative;
    padding: 5px 0;  /* 减小内边距 */
}

.a {
    color: #000000;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    font: bold 18px/1.4 'Optima', arial, sans-serif;

    display: inline-block;
    padding: 5px 20px;  /* 减小上下内边距 */
    position: relative;
}

.a:hover {
    color: #00a5f8 !important;/* 将颜色改为你想要的颜色 */
}

.a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #00a5f8;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.a:hover:after {
    width: 100%;
    left: 0;
}

@media screen and (max-height: 300px) {
    ul {
        margin-top: 40px;
    }
}

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    padding-bottom: 60px; /* 为页脚留出空间 */
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--color-bg);
    backdrop-filter: blur(1px);
    z-index: 1000;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px;  /* 移除内边距 */
}

.personal-info {
    padding-left: 30px;
    display: flex;
    align-items: flex-end;  /* 改为底部对齐 */
}

.personal-info h3 {
    font-family: 'Segoe UI', arial, sans-serif;
    font-size: 1em;
    color: #000000;
    white-space: nowrap;  /* 防止文本换行 */
    /* display: inline-block;  让h3表现为行内块级元素 */
    margin-bottom: 0;  /* 移除底部边距确保完全底部对齐 */
}

.main-content {
    flex: 1;
    margin-top: 45px; /* 减小顶部边距 */
    padding: 0px;

}

.footer {
    background-color: var(--color-bg);
    padding: 20px;
    text-align: center;
    position: absolute; /* 改为absolute定位 */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px; /* 固定页脚高度 */
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    font: 12px/1.4 'Optima', arial, sans-serif;
    color: #666;
}